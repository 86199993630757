import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/HeroImageBlog.scss"

export default function HeroImageFashionTech() {
    const data = useStaticQuery(graphql`
    query MyQuery102 {
      allContentfulHeroImage(filter: { name: { eq: "Luxe Blog Hero Image" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `)

    return (
        <div className="heroImageBlogContainer">
            <img
              className={"heroImageBlog"}
              alt="luxury thought leadership"
              key={``} 
              src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}

            />
        </div>
    )
}