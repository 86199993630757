import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogHome from "../components/BlogPage/BlogHome"
import HeroImageBlog from "../components/BlogPage/HeroImageBlog"
import { Helmet } from "react-helmet"

function bloghome({ data }) {
  return (
    <LayoutBlog>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
        <title>Blog Home</title> {/* Optional: Add a title for SEO */}
      </Helmet>
      <HeroImageBlog />
      {/* <BlogCategoryBar /> */}
      <BlogHome />
    </LayoutBlog>
  )
}

export default bloghome
