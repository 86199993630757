import React, { useEffect, useState } from "react";
import "../scss/BlogHome.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import FK from "./marque_overlay.svg";
import FK1 from "./svg_white.png";
import bloghome3 from "./blog home 3.png";
import ln from "./leena_blog_home.png";
import { Helmet } from "react-helmet";
import bloghome4 from "./blog home 44.png"; 
import press from "./presss.png"; 
import investment from "./investment.png";
import LCEhome from "./LCEhome.png"; 
import bloghome7 from "./bloghome7.png"; 

export default function BlogHome() {
  const data = useStaticQuery(graphql`
  query MyQuery200 {
    allContentfulHeroImage (filter:{id:{eq:"883e2d6d-ddc6-5719-94f6-a0e0f4840903"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          callToAction
          author {
            firstName
          }
          id
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulTextBlock (filter:{id:{eq:"4a7bfd8f-183d-5c2f-b943-5e2fcc527835"}}){
      edges {
        node {
          id
          bodyText {
            raw
          }
        }
      }
    }
  }
  `);

  const description = JSON.parse(
    data.allContentfulTextBlock.edges[0].node.bodyText.raw
  );

  return (
    <>
      <Helmet>
        <title>Luxury Thought Leadership</title>
        <meta name="description" content="Guides, Thoughts and Opinions for Luxury Leaders and Investors - Scientia est Potentia - Knowledge is Power" />
        <meta name="keywords" content="luxury thought leadership, luxury digital transformation" />
        <h1>Guides, Thoughts and Opinions for Luxury Leaders and Investors</h1>
      </Helmet>
    <div className="contentFeatureBlogMain">
      {/* <div className="blogMainCategoryBar">
        <div className="categoriesBlogMain">
          <p className="category">Leadership</p>
        </div>
      </div> */}
      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/the-fresh-kid">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury thought leadership"
                  src={edge.node.heroImage.image.file.url}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">{edge.node.blogCategory}</div>
              <div className="postReadTime">{edge.node.readTime}</div>
              <div className="postTitle">{edge.node.blogTitle}</div>
              <div className="postDateAuthor">
                <p className="postText">{edge.node.date}</p>
                <p className="postText">{edge.node.author.firstName}</p>
              </div>
              <div className="postDescription">
                {documentToReactComponents(description)}
              </div>
              <div className="postCallToAction">
                <Link to="/the-fresh-kid" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>

      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/luxury-diversity-and-inclusion">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={ln}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Luxury Leadership</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">Diversity and Inclusion in <br></br>Luxury Leadership</div>
              <div className="postDateAuthor">
                <p className="postText">11th January 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>Discover the age, gender, education and socio-economic status of luxury's top ceo's </p>
              </div>
              <div className="postCallToAction">
                <Link to="/luxury-diversity-and-inclusion" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>

      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/what-is-luxury-digitaltransformation">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={bloghome3}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Luxury Digital Transformation</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">Agility Not Profit Key to Success of <br></br>Luxury Brands</div>
              <div className="postDateAuthor">
                <p className="postText">14th January 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>Profit is no longer key to success for billion dollar <br></br>luxury brands.</p>
              </div>
              <div className="postCallToAction">
                <Link to="/what-is-luxury-digitaltransformation" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>



      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/mindfulness-luxuryleadership">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={bloghome4}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Luxury Leadership</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">A Complete Mindfulness Guide for<br></br>Luxury Leaders</div>
              <div className="postDateAuthor">
                <p className="postText">21st January 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>Luxury leaders learn how to be more productive, train your mind and protect your health. </p>
              </div>
              <div className="postCallToAction">
                <Link to="/mindfulness-luxuryleadership" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>






      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/press-releases/luxury-diversity-and-inclusion">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={press}
                />
                <img className="svgOverlay1" src={FK1} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Press Releases</div>
              <div className="postReadTime">1 Minute Read</div>
              <div className="postTitle">The Fresh Kid -<br></br>Luxury Data Analyst Releases</div>
              <div className="postDateAuthor">
                <p className="postText">25th January 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>The 'Diversity and Inclusion in Luxury Leadership' report is an independent analysis of top luxury ceos.</p>
              </div>
              <div className="postCallToAction">
                <Link to="/press-releases/luxury-diversity-and-inclusion" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>


      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/luxury-customeranalysis">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={investment}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Luxury Fund Managers</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">Top 5 Luxury Customer Trends for Luxury <br></br>Fund Managers</div>
              <div className="postDateAuthor">
                <p className="postText">14th February 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>De-Risk and protect luxury investments. Discover the top 5 luxury customer trends.</p>
              </div>
              <div className="postCallToAction">
                <Link to="/luxury-customeranalysis" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>



      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/what-is-luxury-circulareconomy">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={LCEhome}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Luxury Circular Economy</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">The Rise of <br></br>Luxury Resale</div>
              <div className="postDateAuthor">
                <p className="postText">16th February 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>Gain new customers with luxury's new <br></br>business model.

</p>
              </div>
              <div className="postCallToAction">
                <Link to="/what-is-luxury-circulareconomy" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>






      <div className="blogContainer">
        {data.allContentfulHeroImage.edges.map((edge, index) => (
          <div key={index} className="blogPost">
            <div className={"imageContainerBlogMain"}>
              <Link to="/examples-for-artificial-intelligence-in-luxury">
                <img
                  className="bigImageBlogMain"
                  alt="Luxury digital transformation"
                  src={bloghome7}
                />
                <img className="svgOverlay" src={FK} alt="Overlay" />
              </Link>
            </div>
            <div className="blogMainPostText">
              <div className="postCategory">Data & AI</div>
              <div className="postReadTime">3 Minute Read</div>
              <div className="postTitle">How AI is Helping Luxury Leaders Enhance Brand and Gain Customers</div>
              <div className="postDateAuthor">
                <p className="postText">3rd March 2024</p>
                <p className="postText">The Fresh Kid</p>
              </div>
              <div className="postDescription">
                <p>Use Artificial Intelligence to Enhance Luxury Brand Perception and Awareness</p>
              </div>
              <div className="postCallToAction">
                <Link to="/examples-for-artificial-intelligence-in-luxury" className="callToActionBlog">
                  {edge.node.callToAction}
                </Link>
              </div>

            </div>
          </div>
        ))}
      </div>




    </div>
    </>
  );
  
}